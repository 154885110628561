import { Box, Heading, SimpleGrid, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { getDaysInMonth, getDate } from 'date-fns';
import Container from '~/components/Container';
import { fetchDayPaymentsStats, fetchMonthPaymentsStats } from '~/core/api/client';
import { QueryKeys } from '~/core/api/swr';
import { getCurrentUserToken } from '~/core/firebase';
import { useInterval } from '~/hooks/interval';
import { commaizeNumber } from '~/utils/number';

const INTERVAL = 60_000; // 1분

export default function DashboardPage() {
  const { data: token } = useSWR(QueryKeys.token, getCurrentUserToken);

  const { data: dayStats, revalidate: revalidateDayStats } = useSWR(
    token != null ? [token, 'dayStats'] : null,
    (token: string) => fetchDayPaymentsStats({ token }).toPromise(),
  );
  const { data: monthStats, revalidate: revalidateMonthStats } = useSWR(
    token != null ? [token, 'monthStats'] : null,
    (token: string) => fetchMonthPaymentsStats({ token }).toPromise(),
  );

  const currentMonthExpectTotalPaidAmount = useMemo(() => {
    if (monthStats == null) {
      return undefined;
    }

    const { totalPaidAmount } = monthStats.stats;
    const now = new Date();

    const totalDays = getDaysInMonth(now);
    const days = getDate(now);

    return Math.floor((totalDays * totalPaidAmount) / days);
  }, [monthStats]);

  const revalidateStats = useCallback(() => {
    revalidateDayStats();
    revalidateMonthStats();
  }, [revalidateDayStats, revalidateMonthStats]);

  useInterval(revalidateStats, {
    delay: INTERVAL,
  });

  return (
    <Container py={8}>
      <Heading as="h1" fontSize={['lg', 'xl']}>
        결제현황
      </Heading>
      <SimpleGrid mt={4} columns={[2, 3]} spacingX={[2, 4]} spacingY={8}>
        <Stat pr={0}>
          <StatLabel>오늘 총 결제금액</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.totalPaidAmount) : '-'}원
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>이번달 총 결제금액</StatLabel>
          <StatNumber>
            {monthStats != null ? commaizeNumber(monthStats.stats.totalPaidAmount) : '-'}원
          </StatNumber>
        </Stat>
        {currentMonthExpectTotalPaidAmount != null ? (
          <Stat pr={0}>
            <StatLabel>이번달 예상 결제금액</StatLabel>
            <StatNumber>{commaizeNumber(currentMonthExpectTotalPaidAmount)}원</StatNumber>
          </Stat>
        ) : null}
        <Box display={['none', 'block']} />
      </SimpleGrid>

      <Heading mt={[16, 20]} as="h1" fontSize={['lg', 'xl']}>
        오늘 결제상세{' '}
        {dayStats != null ? (
          <Text fontSize="sm" fontWeight={400} mt={1}>
            ({dayStats.title})
          </Text>
        ) : null}
      </Heading>
      <SimpleGrid mt={4} columns={[2, 3]} spacingX={[2, 4]} spacingY={8}>
        <Stat pr={0}>
          <StatLabel>결제완료</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.totalPaidCount) : '-'}건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>결제취소</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.totalCancelledCount) : '-'}건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>미결제</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.totalUnpaidCount) : '-'}건
          </StatNumber>
        </Stat>

        <Stat pr={0}>
          <StatLabel>신용카드</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.statsByPayMethods.card.count) : '-'}건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>실시간계좌이체</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.statsByPayMethods.trans.count) : '-'}
            건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>무통장입금</StatLabel>
          <StatNumber>
            {dayStats != null ? commaizeNumber(dayStats.stats.statsByPayMethods.vbank.count) : '-'}
            건
          </StatNumber>
        </Stat>
      </SimpleGrid>

      <Heading mt={[16, 20]} as="h1" fontSize={['lg', 'xl']}>
        이번달 결제상세{' '}
        {monthStats != null ? (
          <Text fontSize="sm" fontWeight={400} mt={1}>
            ({monthStats.title})
          </Text>
        ) : null}
      </Heading>
      <SimpleGrid mt={4} columns={[2, 3]} spacingX={[2, 4]} spacingY={8}>
        <Stat pr={0}>
          <StatLabel>결제완료</StatLabel>
          <StatNumber>
            {monthStats != null ? commaizeNumber(monthStats.stats.totalPaidCount) : '-'}건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>결제취소</StatLabel>
          <StatNumber>
            {monthStats != null ? commaizeNumber(monthStats.stats.totalCancelledCount) : '-'}건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>미결제</StatLabel>
          <StatNumber>
            {monthStats != null ? commaizeNumber(monthStats.stats.totalUnpaidCount) : '-'}건
          </StatNumber>
        </Stat>

        <Stat pr={0}>
          <StatLabel>신용카드</StatLabel>
          <StatNumber>
            {monthStats != null
              ? commaizeNumber(monthStats.stats.statsByPayMethods.card.count)
              : '-'}
            건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>실시간계좌이체</StatLabel>
          <StatNumber>
            {monthStats != null
              ? commaizeNumber(monthStats.stats.statsByPayMethods.trans.count)
              : '-'}
            건
          </StatNumber>
        </Stat>
        <Stat pr={0}>
          <StatLabel>무통장입금</StatLabel>
          <StatNumber>
            {monthStats != null
              ? commaizeNumber(monthStats.stats.statsByPayMethods.vbank.count)
              : '-'}
            건
          </StatNumber>
        </Stat>
      </SimpleGrid>
    </Container>
  );
}
