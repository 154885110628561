import { Box } from '@chakra-ui/react';
import React, { ComponentProps } from 'react';

export const CONTAINER_MAX_WIDTH = 720;

type Props = ComponentProps<typeof Box>;

export default function Container({
  as,
  maxWidth = CONTAINER_MAX_WIDTH,
  children,
  ...props
}: Props) {
  return (
    <Box
      as={as}
      maxWidth={maxWidth != null ? `${maxWidth}px` : undefined}
      px={4}
      mx="auto"
      {...props}
    >
      {children}
    </Box>
  );
}
