import { useEffect, useRef } from 'react';

interface IntervalOptions {
  /**
   * @description 각 Effect 사이의 딜레이를 지정합니다.
   */
  delay: number | null;
  /**
   * @description 만약 false로 지정된 경우 Effect를 즉시 실행시킵니다.
   */
  trailing?: boolean;
}

export function useInterval(callback: () => void, { delay, trailing }: IntervalOptions) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (trailing === false && savedCallback.current) {
      savedCallback.current();
    }
  }, [trailing]);

  useEffect(() => {
    if (delay === null) {
      return () => {
        return;
      };
    }

    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    const id = window.setInterval(tick, delay);
    return () => window.clearInterval(id);
  }, [delay]);
}
